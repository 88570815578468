export interface Experiment<ParamValues> {
    name: string;
    paramName: string;
    values: ParamValues;
}

export type ExperimentMap = Record<string, ExperimentData>;
export type ExperimentData = Record<string, unknown>;

export enum SearchThirdAdValues {
    control = 'control',
    increaseAds = 'variant',
}

export const SearchThirdAd: Experiment<typeof SearchThirdAdValues> = {
    name: 'web_search_third_ad',
    paramName: 'variant',
    values: SearchThirdAdValues,
};

export enum SearchEventCardSignalsVisibilityExperimentValues {
    control = 'control',
    reduced = 'reduced',
}

export const SearchEventCardSignalsVisibilityExperiment: Experiment<
    typeof SearchEventCardSignalsVisibilityExperimentValues
> = {
    name: 'search-event-card-signals-visibility',
    paramName: 'signals_visibility',
    values: SearchEventCardSignalsVisibilityExperimentValues,
};

export enum SearchEventCardClickBehaviourExperimentValues {
    control = 'control',
    fullClick = 'full_click',
    // "Call to action"
    cta = 'cta',
}

export const SearchEventCardClickBehaviourExperiment: Experiment<
    typeof SearchEventCardClickBehaviourExperimentValues
> = {
    name: 'search_event_card_click_behavior',
    paramName: 'click_behavior',
    values: SearchEventCardClickBehaviourExperimentValues,
};

export const HomepageSeasonalCarouselValues = {
    control: 'hide',
    test: 'show',
};

export const HomepageSeasonalCarouselExperiment: Experiment<
    typeof HomepageSeasonalCarouselValues
> = {
    name: 'mardi_gras_new_orleans',
    paramName: 'mardi_gras_carousel',
    values: HomepageSeasonalCarouselValues,
};

export const DatingInNightlifeValues = {
    control: 'hide',
    test: 'show',
};

export const DatingInNightlifeExperiment: Experiment<
    typeof DatingInNightlifeValues
> = {
    name: 'dating_carousel_on_homepage',
    paramName: 'dating_tab',
    values: DatingInNightlifeValues,
};

export const DatingInHomepageValues = {
    control: 'hide',
    test: 'show',
};

export const DatingInHomepageExperiment: Experiment<
    typeof DatingInHomepageValues
> = {
    name: 'dating_carousel_on_homepage_v2',
    paramName: 'dating_carousel',
    values: DatingInHomepageValues,
};

export const EventsNearYouCarouselValues = {
    control: 'hide',
    test: 'show',
};

export const EventsNearYouCarouselExperiment: Experiment<
    typeof EventsNearYouCarouselValues
> = {
    name: 'homepage_events_near_you_carousel',
    paramName: 'events_near_you_carousel',
    values: EventsNearYouCarouselValues,
};

export const TrendingTagEventsValues = {
    control: 'hide',
    test: 'show',
};

export const TrendingTagEventsExperiment: Experiment<
    typeof TrendingTagEventsValues
> = {
    name: 'trending_tag_events_v2',
    paramName: 'trending_events',
    values: DatingInHomepageValues,
};

export const SearchDesktopMapValues = {
    control: 'show',
    variant: 'hide',
};

export const SearchDesktopMapExperiment: Experiment<
    typeof SearchDesktopMapValues
> = {
    name: 'removing_map_experiment2',
    paramName: 'map',
    values: SearchDesktopMapValues,
};

export enum ExperienceFiltersExperimentValues {
    control = 'hide',
    test = 'show',
}

export const ExperienceFiltersExperiment: Experiment<
    typeof ExperienceFiltersExperimentValues
> = {
    name: 'search_experience_filters',
    paramName: 'show_experience_filters',
    values: ExperienceFiltersExperimentValues,
};

export enum EventCardImageExperimentValues {
    control = 'control',
    multi = 'carousel_ads',
}

export const EventCardHomepageImageExperiment: Experiment<
    typeof EventCardImageExperimentValues
> = {
    name: 'homepage_carousel_image_ads',
    paramName: 'carousel_ads',
    values: EventCardImageExperimentValues,
};

export enum SearchEventCardLikesCountExperimentValues {
    control = 'not_show',
    showLikes = 'show',
}

export const SearchEventCardLikesCountExperiment: Experiment<
    typeof SearchEventCardLikesCountExperimentValues
> = {
    name: 'web_search_bookmarks_count_ads',
    paramName: 'show_likes_count',
    values: SearchEventCardLikesCountExperimentValues,
};

export const EventsNearYouCategoryBrowseValues = {
    control: 'hide',
    test: 'show',
};

export const EventsNearYouCategoryBrowseExperiment: Experiment<
    typeof EventsNearYouCategoryBrowseValues
> = {
    name: 'category_distance_category_browse',
    paramName: 'nearby_carousel',
    values: EventsNearYouCategoryBrowseValues,
};

export const OpenDiscountValues = {
    control: 'hide',
    test: 'show',
};

export const OpenDiscountHomepageExperiment: Experiment<
    typeof OpenDiscountValues
> = {
    name: 'open_discount_in_organic_events_homepage',
    paramName: 'show_open_discount',
    values: OpenDiscountValues,
};
