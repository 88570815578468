import {
    OpenDiscountHomepageExperiment,
    useGetStatsigEnrollment,
} from '@eventbrite/discover-utils';
import { isMediumWideDown } from '@eventbrite/eds-utils';
import {
    EventCardActions,
    EventCardBadge,
    EventCardBOGOLabel,
    EventCardDate,
    EventCardDetails,
    EventCardDetailsSpacer,
    EventCardLink,
    EventCardLocation,
    EventCardOrganizer,
    EventCardPrice,
    EventCardPromoCode,
    EventCardPromoted,
    EventCardSaveAction,
    EventCardShareAction,
    EventCardTitle,
    EventCardTracking,
    EventCardUrgencySignals,
    getEventCardLinkProps,
    getFormattedSalesStatus,
    getShareActionProps,
    HORIZONTAL_LEFT_CARD_BADGE_STYLE,
    isPromoCodeActive,
    useFormattedLocationString,
    useGetFormattedEventDateTimeString,
    useMinPriceString,
    VerticalEventCard,
    VERTICAL_EVENT_CARD_IMAGE_CONFIG,
} from '@eventbrite/event-renderer';
import React, { useState } from 'react';
import { useEnvContext, useRouteContext } from '../../../context';
import {
    useMapExperiment,
    useMultiImageExperiment,
} from '../../../pages/search/hooks';
import { defaultUtmOptions } from '../defaults';
import { EventCardMultiImage } from '../Experiments/EventCardMultiImage';
import { getEventCardSignalsVisibility } from '../Experiments/eventCardSignalsVisibility';
import { DiscoverEventCardProps } from '../types';
import styles from './DiscoverVerticalEventCard.module.scss';

export const DiscoverVerticalEventCard: React.FunctionComponent<
    DiscoverEventCardProps
> = ({
    event,
    locale,
    isRaised,
    showOrganizer = true,
    statsigLocationString,
    moreActions,
    affCode = 'ebdssbdestsearch',
    'data-testid': dataTestId,
    isExpansionsLoading,
    shareOptions = {},
    bucketLabel,
    isAuthenticated,
    isLazyImage,
    hasReducedSignals = false,
    hasFullClick,
    statsigMetadata,
    isShowLikesExperimentEnabled = false,
    onClick,
    onShare,
}) => {
    const { user } = useEnvContext();
    const withUpsellModal = !!user?.orgHasEvents;

    const { urgencySignals } = event;
    const { utmOptions = defaultUtmOptions } = shareOptions;

    const { showUrgencySignals, showDiscountCode, showBOGOLabel } =
        getEventCardSignalsVisibility({
            event,
            withLimitedVisibility: hasReducedSignals,
        });

    const linkProps = getEventCardLinkProps({
        affCode: affCode,
        event,
        onClick,
    });

    const formattedDateString = useGetFormattedEventDateTimeString({
        startDate: event?.startDate,
        startTime: event?.startTime,
        timezone: event?.timezone,
        repeatingInstanceCount: event?.repeatingInstanceCount,
        locale,
        useUserTimezone: event.isOnlineEvent,
        dateTimeDelimeter: '•',
    });

    const formattedPriceRangeString = useMinPriceString({
        price: event?.minPrice,
        locale,
    });

    const formattedLocationString = useFormattedLocationString({
        venue: event.venue,
        style: 'venue',
    });

    //Only show active Sales Status (sold out etc)
    //For non-repeating events to prevent incorrect signals.
    const formattedSalesStatus = getFormattedSalesStatus(event);

    const shouldShowUrgencySignals =
        !!urgencySignals && !formattedSalesStatus && showUrgencySignals;

    const eventCardTrackingProps = {
        statsigLocationString: statsigLocationString,
        event,
        eventBucketLabel: bucketLabel?.toString(),
        statsigMetadata,
    };

    const isMapExperimentEnabled = useMapExperiment();

    const renderOrganizer = () => {
        if (showOrganizer) {
            return (
                <EventCardOrganizer
                    organizerFollowerCount={event.organizerFollowerCount}
                    organizerName={event.organizerName}
                    variantStyle="body-md-bold"
                    className="eds-text-color--ui-800"
                />
            );
        }
        return null;
    };
    const [isCardFocused, setCardFocused] = useState(false);
    const hasMultiImageEnabled = useMultiImageExperiment(event);

    const routeContext = useRouteContext();

    const openDiscountHomepageExperiment = useGetStatsigEnrollment({
        name: OpenDiscountHomepageExperiment.name,
        paramName: OpenDiscountHomepageExperiment.paramName,
        defaultValue: OpenDiscountHomepageExperiment.values.control,
        enabled:
            routeContext.location?.pathname === '/' &&
            !event.isPromoted &&
            isPromoCodeActive(event.openDiscount),
    });

    return (
        <section
            className="discover-vertical-event-card"
            data-testid={dataTestId}
            onMouseOver={() => setCardFocused(true)}
            onMouseLeave={() => setCardFocused(false)}
        >
            <VerticalEventCard
                actionVisibility={
                    isMapExperimentEnabled && isMediumWideDown()
                        ? 'visible'
                        : 'hidden'
                }
                isRaised={isRaised}
            >
                <EventCardTracking {...eventCardTrackingProps}>
                    <EventCardBadge style={HORIZONTAL_LEFT_CARD_BADGE_STYLE}>
                        {formattedSalesStatus}
                    </EventCardBadge>
                    <EventCardMultiImage
                        event={event}
                        linkProps={linkProps}
                        isLazyImage={isLazyImage ? 'lazy' : 'eager'}
                        isCardFocused={isCardFocused}
                        tokens={{
                            ...VERTICAL_EVENT_CARD_IMAGE_CONFIG,
                            '--image-background-color': event.edgeColor,
                        }}
                        hasMultiImageEnabled={hasMultiImageEnabled}
                    />
                    <EventCardDetails
                        space="xxs-spacing"
                        tokens={{
                            '--EventCardDetailsPadding': '8px 8px 16px 12px',
                            '--EventCardDetailsFlexGrow': '2',
                            '--EventCardDetailsPosition': 'relative',
                        }}
                    >
                        {shouldShowUrgencySignals && (
                            <EventCardUrgencySignals
                                className={styles.urgencySignals}
                                urgencySignals={urgencySignals}
                            />
                        )}
                        <EventCardLink {...linkProps}>
                            <EventCardTitle
                                color="neutral-800"
                                className="event-card__clamp-line--two"
                                title={event.name}
                            />
                        </EventCardLink>
                        <EventCardDate
                            color="neutral-800"
                            formattedDateString={formattedDateString}
                            variant="body-md-bold"
                        />
                        <EventCardLocation
                            className="event-card__clamp-line--one"
                            formattedLocationString={formattedLocationString}
                            variant="body-md"
                        />
                        <EventCardDetailsSpacer />
                        <div className={styles.priceWrapper}>
                            <EventCardPrice
                                color="neutral-800"
                                isLoading={isExpansionsLoading}
                                isFree={event.isFree}
                                formattedPriceString={formattedPriceRangeString}
                                variant="body-md-bold"
                            />
                            {showDiscountCode &&
                                (openDiscountHomepageExperiment.variant ===
                                    OpenDiscountHomepageExperiment.values
                                        .test ||
                                    event.isPromoted) && (
                                    <EventCardPromoCode
                                        openDiscount={event.openDiscount}
                                        locale={locale}
                                    />
                                )}
                            {showBOGOLabel && (
                                <EventCardBOGOLabel
                                    specialDiscounts={event.specialDiscounts}
                                />
                            )}
                        </div>

                        {renderOrganizer()}

                        <EventCardPromoted
                            className={styles.promotedLabel}
                            isPromoted={event.isPromoted}
                            withUpsellModal={withUpsellModal}
                        />

                        <EventCardActions
                            tokens={{
                                '--EventCardActionsPosition': 'absolute',
                                '--EventCardActionsTop': hasMultiImageEnabled
                                    ? '-65px'
                                    : '-48px',
                                '--EventCardActionsRight': '8px',
                                '--EventCardActionsGap': '8px',
                            }}
                            actionStyleOverride="outline"
                        >
                            <EventCardSaveAction
                                isAuthenticated={!!isAuthenticated}
                                savedByYou={event.savedByYou}
                                id={event.id}
                                eventName={event.name}
                                imageUrl={event.imageUrl}
                                isShowLikesExperimentEnabled={
                                    isShowLikesExperimentEnabled
                                }
                                bookmarksCount={event.bookmarksCount}
                            />
                            <EventCardShareAction
                                {...getShareActionProps({
                                    name: event.name,
                                    id: event.id,
                                    url: event.url,
                                    affCode: affCode,
                                    utmOptions,
                                    onClick: onShare,
                                })}
                            />
                            {moreActions}
                        </EventCardActions>
                    </EventCardDetails>
                    {hasFullClick && (
                        <EventCardLink
                            {...linkProps}
                            data-testid="event-card-full-click-link"
                            className={styles.fullClickLink}
                        />
                    )}
                </EventCardTracking>
            </VerticalEventCard>
        </section>
    );
};
